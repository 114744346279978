<template>
    <div class="slider-swiper" ref="slider">
        <div class="swiper-wrapper">
            <slot></slot>
        </div>

        <div class="slider__navigation">
            <div class="slider__pagination">
                <div class="swiper-button-prev" ref="prev">
                    <IconLeft/>
                </div>
                <div class="swiper-button-next" ref="next">
                    <IconRight/>
                </div>
            </div>
        </div>
    </div>
</template>


<script lang="ts" setup>
import Swiper, { Autoplay, Navigation } from 'swiper';
import { onMounted, ref } from 'vue';
import IconLeft from '../../../icons/icon-chevron-left-small.svg?component';
import IconRight from '../../../icons/icon-chevron-right-small.svg?component';

const slider = ref();
const prev = ref();
const next = ref();
const scrollbar = ref();

onMounted(() => {
    new Swiper(slider.value, {
        modules: [Navigation, Autoplay],
        // Optional parameters
        direction: 'horizontal',
        loop: true,
        speed: 2000,
        slidesPerView: 1,
        spaceBetween: 0,
        autoplay: {
            delay: 5000,
        },
        grabCursor: true,
        navigation: {
            nextEl: next.value,
            prevEl: prev.value,
        },
        scrollbar: {
            el: scrollbar.value,
        }
    });
});
</script>

<style lang="scss" scoped>
.slider-swiper {
  position: relative;
}

.slider__navigation {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px;

  .swiper-scrollbar {
    width: 80px;
    height: 3px;
    left: 0;
    background: $dark;
    border-radius: 0;
    position: relative;

    .swiper-scrollbar-drag {
      border-radius: 0;
      background: $secondary;
    }
  }

  .slider__pagination {
    display: flex;
    flex: 1 auto;
    justify-content: space-between;
    gap: 15px;

    .swiper-button-next,
    .swiper-button-prev {
      opacity: 0;
      transition: opacity $transition-duration $cubic-bezier;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 36px;
      width: 36px;
      border-radius: 100%;
      background: $dark;
      color: $white;
      transform: translate(0, 0);
      left: auto;
      right: auto;
      top: auto;
      margin: 0;
    }
  }
}

.swiper-wrapper:hover + .slider__navigation .swiper-button-next,
.swiper-wrapper:hover + .slider__navigation .swiper-button-prev,
.slider__pagination:hover .swiper-button-prev,
.slider__pagination:hover .swiper-button-next,
.swiper-button-prev:hover,
.swiper-button-next:hover {
  opacity: 1;
}
</style>