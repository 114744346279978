<template>
    <div class="posts-loader" ref="root">
        <PostsFilter v-if="!hideFilter" :filter-view-mode="filterViewMode"
                     :filter="filter as PaginatorFilter"></PostsFilter>
        <div class="boxes__wrapper">
            <div class="boxes__content">
                <template v-if="paginator.posts.value?.length > 0">
                    <template v-if="filterViewMode === 'tabs'">
                        <div class="tabs__post__wrapper">
                            <div class="row">
                                <div class="col s12 l10 offset-l1">
                                    <slot v-bind:paginator="paginator" v-bind:components="components"></slot>
                                </div>
                            </div>
                        </div>
                    </template>
                    <slot v-else v-bind:paginator="paginator" v-bind:components="components"></slot>
                </template>
                <div v-else-if="paginator.loading.value">
                    {{ $t('loading') }}
                </div>
                <div v-else-if="paginator.page.value < 0">
                    {{ $t('no_posts') }}
                </div>
                <div v-if="paginator.posts.value.length !== 0" class="load-more__button">
                    <button
                            v-if="loadMore && !paginator.fetchedAll.value"
                            class="load-more load-more-button btn btn-secondary"
                            @click="paginator.fetchPosts"
                            :disabled="paginator.loading.value"
                    >
                        <span v-if="paginator.loading.value">{{ $t('loading') }}</span>
                        <span v-else>{{ $t('load_more') }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { defineComponent, PropType, ref, watch } from 'vue';
import {
    DiviTaxonomyDefinition,
    PaginatorFilter,
    usePaginator,
    usePaginatorFilter
} from '@/js/vue/composition/paginator';
import PostsFilter from '@/js/vue/components/Filter.vue';

// Hold a state of all components to prevent re-rending the list when adding or filtering items.
const components = {};

const definePostComponent = (template: string) => defineComponent({
    name: 'post-content',
    template,
});

const props = defineProps({
    taxonomies: {
        type: Array as PropType<Array<DiviTaxonomyDefinition | Array<DiviTaxonomyDefinition>>>,
        required: true,
    },
    loadMore: {
        type: Boolean,
        default: () => true,
    },
    hideFilter: {
        type: Boolean,
        default: () => false,
    },
    paginatorOptions: {
        type: Object,
        required: true,
    },
    filterViewMode: {
        type: String,
    },
});

const root = ref<HTMLElement>();

const paginator = usePaginator({
    ...(props.paginatorOptions as any),
    taxonomies: props.taxonomies,
    requestTermCounts: true,
    url: props.paginatorOptions.url + '&filterViewMode=' + props.filterViewMode,
    initialPage: -1,
    fetch: false,
    hideFilter: props.hideFilter,
});

const filter = usePaginatorFilter({
    paginator: paginator,
    scrollToElementOnFilterRestore: root,
    singleFilter: ['tabs', 'dropdown'].indexOf(props.filterViewMode) >= 0,
    requireFilter: props.filterViewMode === 'tabs',
});

watch(paginator.posts.value, (posts) => {
    posts.forEach(post => {
        if (!components.hasOwnProperty(post.id)) {
            components[post.id] = definePostComponent(post.content);
        }
    });
});
</script>
