<template>
    <div class="vue--plyr-player" v-bind:class="{ playing: playing }">
        <div class="app-plyr-video__player-wrapper">
            <div class="app-plyr-video__player-inside">
                <div class="app-plyr-video__player-video">
                    <div ref="player" id="player"></div>
                </div>
            </div>
        </div>

        <div class="app-plyr-preview" @click="play" v-show="!started">
            <slot name="preview" :play="play"></slot>
        </div>
    </div>
</template>

<script>
import Plyr from 'plyr';
import '~/plyr/dist/plyr.css';

const EVENT_VIDEO_STOP_OTHERS = 'video/stop-others';

export default {
    data: () => ({
        player: null,
        playing: false,
        started: false
    }),
    props: {
        id: {
            type: String,
            required: true
        },
        // Whether the video should be pre-loaded or when clicking the play button
        // in the preview screen
        preload: {
            type: Boolean,
            default: () => false
        }
    },
    created() {
        this.$bus.on(EVENT_VIDEO_STOP_OTHERS, component => {
            if (component !== this && !!this.player) {
                this.player.pause();
            }
        });
    },
    mounted() {
        this.$refs.player.setAttribute('data-plyr-provider', 'youtube');
        this.$refs.player.setAttribute('data-plyr-embed-id', this.id);

        if (this.preload) {
            this.initPlyrPlayer();
        }
    },
    methods: {
        initPlyrPlayer() {
            if (this.player) {
                return;
            }

            this.player = new Plyr(this.$refs.player, {
                debug: true,
                autoplay: false,
                youtube: {
                    noCookie: true,
                },
                controls: [
                    // 'play-large',
                    'play',
                    // 'progress',
                    // 'current-time',
                    'mute',
                    // 'volume',
                    // 'captions',
                    // 'settings',
                    // 'pip',
                    // 'airplay',
                    'fullscreen',
                ],
            });

            this.player.on('ready', () => {
                // this.player.play();
            });

            this.player.on('playing', () => {
                this.started = true;
                this.loading = false;
                this.$bus.emit(EVENT_VIDEO_STOP_OTHERS, this);
            });

            this.player.on('pause', () => {
                this.playing = false;
            });
        },
        play() {
            this.started = true;

            if (!this.preload && !this.player) {
                this.initPlyrPlayer();
            }

            this.player.play();
        },
        stop() {
            this.player.pause();
        },
    }
};
</script>

<style lang="scss">
.vue--plyr-player {
  position: relative;
  overflow: hidden;

  .app-plyr-preview {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;

    .app-plyr-video__poster {
      cursor: pointer;
      position: relative;
      height: 100%;
      background: $black;

      img {
        opacity: 0.3;
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .app-plyr-video__poster__content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 0);
        margin-top: -40px;
        text-align: center;
        color: $white;
        width: 100%;
        padding: 0 50px;
        @include media(S) {
          padding: 0 20px;
        }

        svg {
          border-radius: 100%;
          @include media(M) {
            width: 60px;
            height: 60px;
          }
        }

        svg,
        svg > g > * {
          transition: all 0.3s $cubic-bezier;
        }

        p {
          margin-bottom: 0;
          @include media(M) {
            margin-top: 10px;
          }
          @include media(S) {
            display: none;
          }
        }
      }

      &:hover .app-plyr-video__poster__content {
        svg > g > circle {
          fill: $primary;
        }

        svg {
          box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.20);
        }
      }
    }
  }

  .app-plyr-video__player-inside {
    width: 100%;
    position: relative;

    .app-plyr-video__player-video {
      position: relative;
      padding-bottom: 56.25%;
      height: 0;
      overflow: hidden;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.vue--plyr-player > .app-plyr-video__player-wrapper {
  display: flex;
}
</style>
