// @ts-ignore
import baguetteBox from 'baguettebox.js';
import Swiper, { Autoplay, Navigation, Pagination, Scrollbar } from 'swiper';

export default () => {
    var wlySwiper = new Swiper('.swiper-container-wly', {
        modules: [Navigation, Scrollbar, Autoplay],
        // Optional parameters
        direction: 'horizontal',
        loop: false,
        speed: 1400,
        spaceBetween: 30,
        grabCursor: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        scrollbar: {
            el: '.swiper-scrollbar'
        }
    });


    var blogSwiper = new Swiper('.swiper-container-blog-single', {
        modules: [Navigation, Pagination, Autoplay],
        // Optional parameters
        direction: 'horizontal',
        loop: true,
        speed: 1400,
        spaceBetween: 30,
        autoplay: {
            delay: 7000,
        },
        grabCursor: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        }
    });


    var boxSwiper = new Swiper('.swiper-container-boxes', {
        modules: [Navigation, Scrollbar, Autoplay],
        // Optional parameters
        direction: 'horizontal',
        loop: false,
        speed: 1400,
        slidesPerView: 1,
        spaceBetween: 30,
        breakpoints: {
            993: {
                slidesPerView: 2
            }
        },
        grabCursor: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        scrollbar: {
            el: '.swiper-scrollbar'
        }
    });
};
