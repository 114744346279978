import $ from 'jquery';

export default () => {
    // Scroll to
    $(document).on('click', 'a[href]:not([href^="mailto\\:"], [href$="\\#"])', function (event) {
        function isValidSelector(selector) {
            if (typeof selector !== 'string') {
                return false;
            }
            try {
                var $element = $(selector);
            } catch (error) {
                return false;
            }
            return true;
        }

        if (isValidSelector($(this).attr('href'))) {
            if ($($.attr(this, 'href')).length && $.attr(this, 'href') !== '#wlymmenu' && $.attr(this, 'href') !== '#') {
                event.preventDefault();
                $('html, body').animate(
                    {
                        scrollTop: $($.attr(this, 'href')).offset().top - $('header').height(),
                    },
                    100
                );
            }
        }
    });

    // Make fixed Navigation
    $(window).bind('scroll', function () {
        if ($(window).scrollTop() > 400) {
            $('body').addClass('fixed-header');
        } else {
            $('body').removeClass('fixed-header');
        }
    });

    // Language Switcher
    $('.header__main__search__icon').click(function() {
        $(this).closest('.header__main__search').addClass('active');
    });
    $('.search__closer').click(function() {
        $(this).closest('.header__main__search').removeClass('active');
    });

    // Search Switcher
    $('.locale-switcher__active-lang').click(function() {
        $(this).closest('.locale-switcher').toggleClass('active');
    });

    // Navigation Touch Geräte
    $('#headerNavbar ul.navbar-nav > li.nav-menu > a').on('touchend', function (e) {
        if (!$(this).parent().hasClass('hovered')) {
            e.preventDefault();
            $(this).parent().toggleClass('hovered');
            $(this).parent().siblings().removeClass('hovered');
        }
    });

    // Header Boxes Combination
    $('.boxes:not(.box-title--active)').prev('.header__wrapper').addClass('header-before-boxes');

    const hoveredItems = $('[class*="hovered"]');

    hoveredItems.each(function () {
        $(this).removeClass('.hovered');
    });


    // Check if Element is out of view
    $.fn.isInViewport = function() {
        const offset = $(this).offset();

        if (!offset) {
            return false;
        }

        var elementTop = offset.top;
        var elementBottom = elementTop + $(this).outerHeight();

        var viewportTop = $(window).scrollTop();

        return elementBottom > viewportTop;
    };

    // Page Scroll Buttons
    $(window).on('resize scroll', function() {
        if ($('.page__buttons__viewport--indicator').isInViewport()) {
            $('body').removeClass('page__buttons--fixed');
        } else {
            $('body').addClass('page__buttons--fixed');
        }
    });

    $(window).scroll(function(){
        var $sections = $('.block__wrapper');
        $sections.each(function(){
            var top  = $(this).offset().top-100;
            var scroll = $(window).scrollTop();
            var id = $(this).attr('id');

            if( scroll > top && id !== ''){

                $('.page__buttons a[href="#'+id+'"]').each(function() {
                    $('a.active').removeClass('active');
                    $(this).addClass('active');
                    $('.page__buttons').scrollLeft(0);
                    var scrolPos = $(this).offset();
                    $('.page__buttons').scrollLeft(scrolPos.left - 30);
                });
            }
        })


    });



};
