import PostsLoader from '@/js/vue/components/Loaders/PostsLoader.vue';
import YoutubePlayer from '@/js/vue/components/YoutubePlayer.vue';
import Plyr from "@/js/vue/components/Plyr.vue";
import Slider from "@/js/vue/components/Slider.vue";

export const registerComponents = (app) => {
    app.component('app-youtube', YoutubePlayer);
    app.component('app-plyr', Plyr);
    app.component('app-slider', Slider);
    app.component('posts-loader', PostsLoader);
};
